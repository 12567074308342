import React from 'react';
import { Drawer, Theme, useMediaQuery } from '@mui/material';
import ApiDocList from './ApiDocList';

const drawerWidth = 260;



const ApiDocSidebar = () => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: lgUp ? 0 : 1,
        [`& .MuiDrawer-paper`]: { position: 'relative' },
      }}
      open={true}

      variant={lgUp ? 'persistent' : 'temporary'}
    >
      <ApiDocList />
    </Drawer>
  );
};

export default ApiDocSidebar;
