import React from 'react';
import {  Box, Typography, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { IconArrowRight, IconCoffee, IconNotes } from '@tabler/icons';
// import {
//   fetchNotes,
//   SelectNote,
//   DeleteNote,
//   SearchNotes,
// } from '../../../store/apps/notes/NotesSlice';
import { selectApiDoc } from 'src/store/app/developer/ApiDocSlice';
import { useDispatch } from 'src/store/Store';
import { isAuthorized } from 'src/utils/authHelper';
import { useNavigate } from 'react-router';

const ApiDocList = () => {

   const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const navigate = useNavigate();
    
    const handleListItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      index: number,
      page: string,
      title: string,
    ) => {
      setSelectedIndex(index);
      if(index===4) {
        if(isAuthorized()) {
          navigate("/developer/api/apiToken");
        } else {
          navigate("/auth/login");
        }
      } else {
        dispatch(selectApiDoc(index, page, title));
      }
    };

    return (
    <>
      <Box p={3} px={2}>
        <Typography variant="h6" mb={0} mt={2} pl={1}>
             API Document
        </Typography>
      </Box>
      <Divider />
      <Box p={2} px={2}>
        <List component="nav" aria-label="main mailbox folders">
            <Typography variant="subtitle2" fontWeight={1000} px={1} mt={1} pb={2} key={1}>
            <IconCoffee size="18" />  Introduction 
            </Typography>
            <ListItemButton
                selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0, 'general-getting-started', 'Getting started')}
                >
              <ListItemText>Getting started</ListItemText>
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1, 'general-endpoint', 'Endpoint URLs')}
                >
              <ListItemText>Endpoint URLs</ListItemText>
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2, 'general-supported-environments', 'Supported Environments')}
                >
              <ListItemText>Supported Environments</ListItemText>
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3, 'general-membership', 'Membership')}
                >
              <ListItemText>Membership</ListItemText>
            </ListItemButton>
            <ListItemButton
                selected={selectedIndex === 4}
                    onClick={(event) => handleListItemClick(event, 4, 'general-get-api-key', 'Get API Token')}
                >
              <ListItemText>Get your API token</ListItemText>
            </ListItemButton>
        </List>
      </Box>
      <Divider />
      <Box p={2} px={2}>
        <List>
            <Typography variant="subtitle2" fontWeight={1000} px={1} mt={1} pb={2} key={1}>
            <IconNotes size="20" />  Contracts
            </Typography>
            <ListItemButton
                selected={selectedIndex === 5}
                    onClick={(event) => handleListItemClick(event, 5, 'contract-overview', 'Overview')}
                >
              <ListItemText>Overview</ListItemText>
            </ListItemButton>
            <ListItemButton>
                <ListItemText
                    disableTypography
                    primary={<Typography variant="h6" style={{ color: '#455a64', fontSize: '15px', fontWeight: '20px'}}>Verification API</Typography>}
                />
            </ListItemButton>
            <List component="div" disablePadding>
                <ListItemButton
                    selected={selectedIndex === 6}
                    onClick={(event) => handleListItemClick(event, 6, 'contract-verification', 'Contract Verification')}
                >
                    <IconArrowRight width={20} height={20} />
                    <ListItemText sx={{ ml: 2 }}>
                        Contract Verification
                    </ListItemText>
                </ListItemButton>
                <ListItemButton
                    selected={selectedIndex === 7}
                    onClick={(event) => handleListItemClick(event, 7, 'contract-callback', 'CallBack')}
                >
                    <IconArrowRight width={20} height={20} />
                    <ListItemText sx={{ ml: 2 }}>
                        CallBack
                    </ListItemText>
              </ListItemButton>
            </List>
        </List>
      </Box>
      <Divider />
    </>
  );
};

export default ApiDocList;
