
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
    exp: number;
    // Define the structure of your decoded token here
}

export const verifyToken = () => {
    const token = sessionStorage.getItem('accessToken');  
    if (!token) {
        // Handle the case where the token is not available
        return false;
    }
    const decodedToken = jwtDecode(token) as DecodedToken;
    const currentDate = new Date();
   // JWT exp is in seconds
   if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return false;
   } else {

    return true;
   }
}
export const isAuthorized = () => {
    return verifyToken();
}

export const getUserInfo = () => {
    const userInfo = sessionStorage.getItem('userInfo');  
    if (!userInfo) {
        return {};
    }

    return JSON.parse(userInfo);
}
export const getUserShortName = () => {
    const userInfo = sessionStorage.getItem('userInfo');  
    if (!userInfo) {
        return '';
    }
    const userData = JSON.parse(userInfo);
    if (userData && userData.name) {
        return userData.name.substring(0, 1);
    } else {
        return '';
    }
}

export const getAuthHeader = () => {
    return {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
    };
}