import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    contract?: any;
    wasmText?: string;
}
const initialState = {
    contract: {},
    wasmText: ''
};

export const ContractSlice = createSlice({
    name: 'BlockchainContract',
    initialState,
    reducers: {
      getContract: (state: StateType, action) => {
        state.contract = action.payload.result;
      },
      getWasmCode: (state: StateType, action) => {
        state.wasmText = action.payload.result.wasmText;
      },
    },
});

export const fetchBlockchainContract = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getContract+"?id="+id}`);
    dispatch(getContract(response.data));
  } catch (err) {

    throw new Error();
  }
};
export const decodeAndDisassemble = (wasmCode: any) => async (dispatch: AppDispatch) => {
  try {
    // Make a POST request to the specified URL with wasmCode in the request body
    const response = await axios.post(ConfigData.URL.decodeAndDisassemble, { wasmCode });
    // Dispatch the action with the response data
    dispatch(getWasmCode(response.data));
  } catch (err) {

    throw new Error();
  }
};


export const { getContract, getWasmCode} = ContractSlice.actions;

export default ContractSlice.reducer;