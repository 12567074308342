import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    offers: any[];
    offer?: any;
}
const initialState = {
    offers: [],
    offer: {},
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const OfferSlice = createSlice({
    name: 'Offer',
    initialState,
    reducers: {
      getOffers: (state: StateType, action) => {
        state.offers = action.payload.result.offers;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getOffer: (state: StateType, action) => {
        state.offer = action.payload.result.offer;
      }
    },
});
export const fetchBlockchainOffers = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getOffers+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getOffers(response.data));
    } catch (err) {
      throw new Error();
    }
};
export const fetchBlockchainOffer = (id: any) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getOffer+"?id="+id}`);
      dispatch(getOffer(response.data));
    } catch (err) {
  
      throw new Error();
    }
  };
export const fetchBlockchainOffersByAccount = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAccountOffers+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getOffers(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const { getOffer, getOffers, } = OfferSlice.actions;

export default OfferSlice.reducer;