import { Box, Chip, Divider, InputAdornment, Link, Stack, Tooltip, Typography, } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import { IconCopy,} from '@tabler/icons';
import React  from 'react';
import PageContainer from 'src/components/container/PageContainer';
import ChildCard from 'src/components/shared/ChildCard';
import CustomContent from 'src/components/shared/doc/CustomContent';
import CustomFormLabel from 'src/components/shared/form/CustomFormLabel';
import CustomTextField from 'src/components/shared/form/CustomTextField';

const Endpoint = () => { 
    const handleCopy = (value: string) => {
      let url = "";
      if(value==='test') {
        url = "https://testopenapi.sorobanexp.com";
      } else if(value==='public') {
        url = "https://openapi.sorobanexp.com";
      }
      navigator.clipboard.writeText(url)
    };

return (
  <>
    <PageContainer>
        <Grid container spacing={3} marginLeft={2}>
            <Grid item xs={12}>
            <Box>
               {/* ------------------------------------------- */}
                {/* API  Detail page */}
                {/* ------------------------------------------- */}
              <Stack p={2} gap={0} direction="column">
                <CustomContent>
                  <p>  
                  Prior to accessing to the public and test RPC endpoints on Sorobanexp, it's crucial to complete the <Link href={'/auth/register'} underline="none">registration</Link> process 
                  for an account. After a successful registration, logging in will provide URL access permission through a generated API token.
                 </p>
                </CustomContent>
                 <CustomContent>
                  <p>  
                      This API token from Sorobanexp is valid for both public and test network explorers. Remember to include the token in the authorization header when accessing the specified endpoint URL.
                 </p>
                </CustomContent>
                <CustomContent>
                  <>The structure of endpoints and parameters remains uniform across both types of explorers, requiring only the adjustment of the API endpoint URL as indicated below.</>
                </CustomContent>
                <Grid container spacing={3} sx={{marginTop: 3}}>
                  <Grid item sm={8}>
                    <ChildCard>
                      <Stack p={2} gap={0} direction="row">
                          <Typography fontWeight={600} variant="h4" mb={2}>
                            Public network
                          </Typography>
                          <Tooltip title='The URL will be available once the public network is live.'>
                          <Chip
                              label='Inactive'
                              sx={{ ml: 'auto', height: '21px' }}
                              size="small"
                              color={'error'
                              }
                            />
                          </Tooltip>
                        </Stack>
                      <Divider/>
                      <Box sx={{marginTop: 3}}>
                          <CustomFormLabel
                              sx={{
                                mt: 0,
                              }}
                              htmlFor="df-name"
                            >
                              HTTPS Endpoint
                            </CustomFormLabel>
                            <CustomTextField
                              id="df-name"
                              variant="outlined"
                              defaultValue="https://openapi.sorobanexp.com"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment 
                                  style={{ marginLeft: '2px', marginTop: '2px', marginRight: '5px', cursor: 'pointer' }} 
                                  position="end" onClick={() => handleCopy("public")}>
                                    <IconCopy size="20" />
                                  </InputAdornment>
                                )
                              }}
                            />

                      </Box>
                    </ChildCard>
                  </Grid>
                  <Grid item sm={4}></Grid>
                </Grid>
                <Grid container spacing={3} sx={{marginTop: 3}}>
                  <Grid item sm={8}>
                    <ChildCard>
                    <Stack p={2} gap={0} direction="row">
                      <Typography fontWeight={600} variant="h4" mb={2}>
                        Test network
                      </Typography>
                      <Chip
                          label='Active'
                          sx={{ ml: 'auto', height: '21px' }}
                          size="small"
                          color={'success'
                          }
                        />
                      </Stack>
                      <Divider/>
                      <Box sx={{marginTop: 3}}>
                          <CustomFormLabel
                              sx={{
                                mt: 0,
                              }}
                              htmlFor="df-name"
                            >
                              HTTPS Endpoint
                            </CustomFormLabel>
                            <CustomTextField
                              id="df-name"
                              variant="outlined"
                              
                              defaultValue="https://testopenapi.sorobanexp.com"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment 
                                  style={{ marginLeft: '2px', marginTop: '2px', marginRight: '5px', cursor: 'pointer' }} 
                                  position="end" onClick={() => handleCopy("test")}>
                                    <IconCopy size="20" />
                                  </InputAdornment>
                                )
                              }}
                            />

                      </Box>
                    </ChildCard>
                  </Grid>
                  <Grid item sm={4}></Grid>
                </Grid>
              </Stack>
            </Box>
                <div> </div>
            </Grid>
        
        </Grid>
    </PageContainer>
    </>
  );
};

export default Endpoint;
