import authAxios from 'src/utils/auth-axios';
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    accessToken: string;
    success: boolean;
    message: string;
    loading: boolean;
}
const initialState = {
    accessToken: '',
    success: true,
    message: '',
    loading: false,
};

export const AuthSlice = createSlice({
    name: 'AuthLogin',
    initialState,
    reducers: {

      toggleLoading: (state: StateType, action) => {
        state.loading = action.payload;
      },
      setDiaplyMessage: (state: StateType, action) => {
        state.message = action.payload;
      },
      toggleSuccess: (state: StateType, action) => {
        state.success = action.payload;
      },
      login: (state: StateType, action) => {
        state.success =  action.payload.success;
        state.message =  action.payload.message;
        if(state.success) {
          const accessToken  = action.payload.accessToken;
          state.accessToken = accessToken;
          sessionStorage.setItem("accessToken",accessToken);

          const user  = action.payload.user;
          sessionStorage.setItem("userInfo", JSON.stringify(user));

          window.location.href = '/developer/api/apiToken';
        }
      },
      logout: (state: StateType, action) => {
        state.success =  action.payload.success;
        sessionStorage.setItem("accessToken","");
        window.location.href = '/';
      },
      signup: (state: StateType, action) => {
        state.success =  action.payload.success;
        state.message =  action.payload.message;
        if(state.success) {
          window.location.href = '/auth/login';
        }
      },
      forgetPassword: (state: StateType, action) => {
        state.success =  action.payload.success;
        state.message =  action.payload.message;
        if(state.success) {
          window.location.href = '/auth/confirmPassword';
        }
      },
      confirmUpdatePassword: (state: StateType, action) => {
        state.success =  action.payload.success;
        state.message =  action.payload.message;
        if(state.success) {
          window.location.href = '/auth/login';
        }
      },
      updatePassword: (state: StateType, action) => {
        state.success =  action.payload.success;
        state.message =  action.payload.message;
      },
    },
});
export const submitLoginForm = (email: string, password: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleLoading(true));
    dispatch(setDiaplyMessage(''));
    const response = await axios.post(`${ConfigData.URL.login}`,{ 
      email: email,
      password: password
    });
    dispatch(login(response.data));
    dispatch(toggleLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(toggleLoading(false));
  }
};
export const submitLogoutForm = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleLoading(true));
    dispatch(setDiaplyMessage(''));
    const response = await authAxios.post(`${ConfigData.URL.logout}`);
    dispatch(logout(response.data));
    dispatch(toggleLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(toggleLoading(false));
  }
};
export const submitSignupForm = (email: string, password: string, name: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleLoading(true));
    dispatch(setDiaplyMessage(''));
    const response = await axios.post(`${ConfigData.URL.signup}`,{ 
      email: email,
      password: password,
      name: name
    });
    dispatch(signup(response.data));
    dispatch(toggleLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(toggleLoading(false));
  }
};
export const submitForgetPasswordForm = (email: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleLoading(true));
    dispatch(setDiaplyMessage(''));
    const response = await axios.post(`${ConfigData.URL.resetpwd}`,{ 
      email: email,
    });
    dispatch(forgetPassword(response.data));
    dispatch(toggleLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(toggleLoading(false));
  }
};
export const submitConfirmUpdatePasswordForm = (email: string, password: string, confirmationCode: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleLoading(true));
    dispatch(setDiaplyMessage(''));
    const response = await axios.post(`${ConfigData.URL.confirmPwd}`,{ 
      email: email,
      password: password,
      confirmationCode: confirmationCode
    });
    dispatch(confirmUpdatePassword(response.data));
    dispatch(toggleLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(toggleLoading(false));
  }
};
export const submitUpdatePasswordForm = (previousPassword: string, proposedPassword: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleLoading(true));
    dispatch(setDiaplyMessage(''));
    const response = await authAxios.post(`${ConfigData.URL.updatepwd}`,{ 
      previousPassword: previousPassword,
      proposedPassword: proposedPassword
    });
    dispatch(updatePassword(response.data));
    dispatch(toggleLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(toggleLoading(false));
  }
};
export const setLoading = (isloading: boolean) => async (dispatch: AppDispatch) => {
  dispatch(toggleLoading(isloading));
};
export const setSuccess = (success: boolean) => async (dispatch: AppDispatch) => {
  dispatch(toggleSuccess(success));
};
export const setMessage = (message: string) => async (dispatch: AppDispatch) => {
  dispatch(setDiaplyMessage(message));
};
export const reset = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(false));
  dispatch(setSuccess(true));
  dispatch(setMessage(''));
};
export const { login, logout, signup, forgetPassword, confirmUpdatePassword, updatePassword, toggleLoading, toggleSuccess, setDiaplyMessage } = AuthSlice.actions;

export default AuthSlice.reducer;