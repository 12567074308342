import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
  nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    ledgers: any[];
    ledger?: any;
    txs: any[];
}
const initialState = {
    ledgers: [],
    ledger: {},
    txs: [],
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const LedgerSlice = createSlice({
    name: 'Ledger',
    initialState,
    reducers: {
      getLedgers: (state: StateType, action) => {
        state.ledgers = action.payload.result.ledgers;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getDashboardLedgers: (state: StateType, action) => {
        state.ledgers = action.payload.result.ledgers;
      },
      getLedger: (state: StateType, action) => {
        state.ledger = action.payload.result.ledger.data;
      },
    },
});
export const fetchBlockchainLedgers = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getLedgers+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getLedgers(response.data));
    } catch (err) {
      throw new Error();
    }
};
export const fetchDashboardLedgers = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getDashboardLedgers}`);
    dispatch(getDashboardLedgers(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainLedger = (ledgerSeq: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLedger+"?ledgerSeq="+ledgerSeq}`);
    dispatch(getLedger(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const { getLedgers, getDashboardLedgers, getLedger } = LedgerSlice.actions;

export default LedgerSlice.reducer;