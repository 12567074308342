import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    assets: any[];
    asset?: any;
}
const initialState = {
    assets: [],
    asset: {},
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const AssetSlice = createSlice({
    name: 'Asset',
    initialState,
    reducers: {
      getAssets: (state: StateType, action) => {
        state.assets = action.payload.result.assets;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getAsset: (state: StateType, action) => {
        state.asset = action.payload.result.asset;
      },
    },
});
export const fetchBlockchainAssets = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getAssets+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getAssets(response.data));
    } catch (err) {
      throw new Error();
    }
};

export const fetchBlockchainAsset = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAsset+"?id="+id}`);
    dispatch(getAsset(response.data));
  } catch (err) {

    throw new Error();
  }
};


export const { getAssets,  getAsset} = AssetSlice.actions;

export default AssetSlice.reducer;