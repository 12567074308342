import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    operations: any[];
    operation?: any;
}
const initialState = {
    operations: [],
    operation: {},
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const OperationSlice = createSlice({
    name: 'Operation',
    initialState,
    reducers: {
      getOperations: (state: StateType, action) => {
        state.operations = action.payload.result.operations;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getDashboardOperations: (state: StateType, action) => {
        state.operations = action.payload.result.operations;
      },
      getOperation: (state: StateType, action) => {
        state.operation = action.payload.result.operation;
      }
    },
});
export const fetchBlockchainOperations = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getOperations+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getOperations(response.data));
    } catch (err) {
      throw new Error();
    }
};
export const fetchDashboardOperations = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getDashboardOperations}`);
    dispatch(getDashboardOperations(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainOperation = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getOperation+"?id="+id}`);
    dispatch(getOperation(response.data));
  } catch (err) {

    throw new Error();
  }
};

export const fetchBlockchainOperationsByLedger = (ledgerId: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLedgerOperations+"?ledgerId="+ ledgerId + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getOperations(response.data));
  } catch (err) {
    throw new Error();
  }
};

export const fetchBlockchainOperationsByTxn = (hash: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getTxnOperations+"?txnHash="+ hash + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getOperations(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainOperationsByLiquidityPool = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLiquidityPoolOperations+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getOperations(response.data));
  } catch (err) {
    throw new Error();
  }
};

export const fetchBlockchainOperationsByAccount = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAccountOperations+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getOperations(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const { getOperation, getDashboardOperations, getOperations, } = OperationSlice.actions;

export default OperationSlice.reducer;