import { Box, Divider, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React  from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import PageContainer from 'src/components/container/PageContainer';
import CustomContent from 'src/components/shared/doc/CustomContent';




const ContractCallback = () => { 

  const jsonData = {
    verifyStatus: 'T',
    processStatus: 'C',
    gitUrl: 'https://github.com/stellar/soroban-examples/tree/v20.0.0-rc2/cross_contract/contract_a',
    runtime: '1.74.1',
    platform: '20.0.0',
    networkWasmHash: '08d2ed3679715e7134eec90ef262743233dd6c6e47fec6ce9e50fdbf0f547baa',
    buildWasmHash: '08d2ed3679715e7134eec90ef262743233dd6c6e47fec6ce9e50fdbf0f547baa',
    datetime: '2023-12-21 16:49:13'
  };

return (
  <>
<PageContainer>
      <Grid container marginLeft={2} paddingRight={10}>
        <Grid item xs={12}>
          <Box sx={{marginRight:3 }}>
             {/* ------------------------------------------- */}
              {/* API  Detail page */}
              {/* ------------------------------------------- */}
            <Stack p={2} gap={0} direction="column">

              <CustomContent>
                <p>  
                When the requested contract verification is completed, sorobanexp will send a callback POST request to your web application in the application/json format. 
                This request includes information about the verification result, 
                such as the verification status, processing status, GitHub URL, runtime version, platform version, network Wasm hash, build Wasm hash, and timestamp.
               </p>
              </CustomContent>
              <Divider/>
              <Box>
                <Typography variant="h5">Example</Typography>
                <SyntaxHighlighter language="json" style={monokaiSublime}>
                  {JSON.stringify(jsonData, null, 2)}
                </SyntaxHighlighter> 
              </Box>

                <Box>
                  <Grid container spacing={1}>
                      <Grid item xs={12}>
                          <TableContainer>
                            <Table sx={{  width: '100%', maxWidth: '100%' }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ width: '10%' }}>Parameter</TableCell>
                                    <TableCell sx={{ width: '20%' }}>Definition</TableCell>
                                    <TableCell sx={{ width: '70%' }}>Explanation</TableCell>
                                  </TableRow>
                                </TableHead>
                                  <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ width: '10%' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                          verifyStatus
                                          </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                        Represents the verification status of the contract.
                                        </TableCell>    
                                        <TableCell sx={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                        <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                        Indicates the outcome of the contract verification process. It has values 'T' for successful verification or 'F' for fail verification .
                                          </Typography>
                                        </TableCell>           
                                      </TableRow>
                                    <TableRow>
                                    <TableCell sx={{ width: '10%' }}>
                                      <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                        processStatus
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                      <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                        Reflects the overall status of the entire verification process.
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                      <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                        Provides information about the progress and completion of the contract verification process. It includes the following status codes:
                                        <br />
                                        'I': Indicates the process is in progress.
                                        <br />
                                        'P': Represents that the process is pending.
                                        <br />
                                        'C': Denotes successful completion of the process.
                                        <br />
                                        'F': Indicates failure of the process.
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={{ width: '10%' }}>
                                      <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                        gitUrl
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                      <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                        Contains the GitHub URL of the contract's source code.
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                      <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                        Points to the location on GitHub where the contract's source code can be found. This URL helps users and developers access the exact code that was verified.
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                      <TableRow>
                                      <TableCell sx={{ width: '10%' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                          runtime
                                          </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                          Indicates the version of the Soroban SDK used for deploying the contract.
                                          </Typography>
                                        </TableCell>  
                                        <TableCell sx={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                          Specifies the version of the Soroban SDK that was utilized to deploy the smart contract on the Soroban network. It provides information about the runtime environment of the contract.
                                          </Typography>
                                        </TableCell>           
                                      </TableRow>
                                      <TableRow>
                                      <TableCell sx={{ width: '10%' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                          platform
                                          </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                          Represents the version of the Rust SDK used for deploying the contract.
                                          </Typography>
                                        </TableCell>  
                                        <TableCell sx={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                          Specifies the version of the Rust SDK employed for deploying the smart contract. This information gives insight into the platform used during the contract deployment process.
                                          </Typography>
                                        </TableCell>         
                                      </TableRow>  
                                      <TableRow>
                                      <TableCell sx={{ width: '10%' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                          networkWasmHash
                                          </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                          Denotes the generated WebAssembly (Wasm) hash for the deployed contract on the Soroban network.
                                          </Typography>
                                        </TableCell> 
                                        <TableCell sx={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                          Represents a unique identifier (hash) generated for the WebAssembly code of the deployed contract within the Soroban network. This hash is useful for referencing and validating the deployed code.
                                          </Typography>
                                        </TableCell>            
                                      </TableRow>  
                                      <TableRow>
                                      <TableCell sx={{ width: '10%' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                          buildWasmHash
                                          </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                          Signifies the generated WebAssembly (Wasm) hash based on building the user-provided GitHub project.
                                          </Typography>
                                        </TableCell>    
                                        <TableCell sx={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                          <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                          Indicates a unique identifier (hash) generated for the WebAssembly code derived from building the user's provided GitHub project. This hash allows verification of the source code's integrity.
                                          </Typography>
                                        </TableCell>         
                                      </TableRow>
                                      <TableRow>  
                                      <TableCell sx={{ width: '10%' }}>
                                            <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                              datetime
                                            </Typography>
                                          </TableCell>
                                          <TableCell sx={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                            <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                              Represents the timestamp indicating when the contract verification occurred.
                                            </Typography>
                                          </TableCell>
                                          <TableCell sx={{ width: '70%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                            <Typography color="textSecondary" variant="subtitle2" fontWeight={400}>
                                              Displays the date and time when the contract verification process was executed. This timestamp provides information about the specific moment when the contract was verified.
                                            </Typography>
                                          </TableCell>  
                                        </TableRow>                     
                                  </TableBody>
                            </Table>
                          </TableContainer>
                      </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Box>
          </Grid>
      </Grid>
  </PageContainer>
    </>
  );
};

export default ContractCallback;
