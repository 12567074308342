import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    accounts: any[];
    account?: any;
    thresholds?: any;
    flags?: any;
    signings: any[];
    balances: any[];

}
const initialState = {
    accounts: [],
    account: {},
    thresholds: {},
    flags: {},
    signings: [],
    balances: [],
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const AccountSlice = createSlice({
    name: 'Account',
    initialState,
    reducers: {
      getAccounts: (state: StateType, action) => {
        state.accounts = action.payload.result.accounts;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getAccount: (state: StateType, action) => {
        state.account = action.payload.result.account;
        state.signings = action.payload.result.signings;
        state.thresholds = action.payload.result.thresholds;
        state.flags = action.payload.result.flags;
        state.balances = action.payload.result.balances;
      },
    },
});
export const fetchBlockchainAccounts = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getAccounts+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getAccounts(response.data));
    } catch (err) {
      throw new Error();
    }
};

export const fetchBlockchainAccount = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAccount+"?id="+id}`);
    dispatch(getAccount(response.data));
  } catch (err) {

    throw new Error();
  }
};

export const { getAccounts, getAccount} = AccountSlice.actions;

export default AccountSlice.reducer;