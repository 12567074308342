import { Box, Button, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Link } from '@mui/material';
import { IconCheck } from '@tabler/icons';
import React, { useState }  from 'react';
import PageContainer from 'src/components/container/PageContainer';
import BlankCard from 'src/components/shared/BlankCard';
import { isAuthorized } from 'src/utils/authHelper';
import { useNavigate} from 'react-router-dom';
import CustomContent from 'src/components/shared/doc/CustomContent';

const Membership = () => { 

    const [hasShowEmail, showEmail] = useState(false)
    const navigate = useNavigate();
    const handleListItemClick = (member: string) => {
        if(member==='Basic') {
            if(isAuthorized()) {
                navigate(" /developer/api/apiToken");  
            } else {
                navigate("/auth/login"); 
            }
        } else if(member==='Partner') {
            if(hasShowEmail) {
                showEmail(false);
            } else {
                showEmail(true);
            }
           
        }
    };

return (
  <>
  <PageContainer>
        <Grid container spacing={3} justifyContent="center" mt={3}>
            <Grid item xs={12} sm={10} lg={8} textAlign="center">
            <Typography variant="h2">
                Flexible Plans Tailored to Fit Community's Needs!
            </Typography>
            </Grid>
        </Grid>
        <Grid container mt={5}>
            <Grid item lg={2} sm={2}>
            </Grid>
            <Grid item xs={12} lg={4} sm={4} sx={{ marginRight: 1 }}>
                <BlankCard>
                    <CardContent sx={{ p: '30px' }}>
                        <Typography
                            variant="subtitle1"
                            fontSize="12px"
                            mb={3}
                            color="textSecondary"
                            textTransform="uppercase"
                            >
                            {'Basic'}
                        </Typography>
                        <Box my={4}>
                            <Box fontSize="50px" mt={5} fontWeight="600">
                                {'Free'}
                            </Box>
                        </Box>

                        <Box mt={3}>
                            <List>
                                <ListItem disableGutters>
                                    <ListItemIcon sx={{ color: 'primary.main', minWidth: '32px' }}>
                                        <IconCheck width={18} />
                                    </ListItemIcon>
                                    <ListItemText>{'30 requests per day'}</ListItemText>
                                </ListItem>
                            </List>
                        </Box>

                        <Button
                            sx={{ width: '100%', mt: 3 }}
                            variant="contained"
                            size="large"
                            onClick={() => handleListItemClick('Basic')}
                            color="secondary">
                        {'GET STARTED NOW'}
                        </Button>
                    </CardContent>
                </BlankCard>
            </Grid>
            <Grid item xs={12} lg={4} sm={4}>
                <BlankCard>
                    <CardContent sx={{ p: '30px' }}>
                        <Typography
                            variant="subtitle1"
                            fontSize="12px"
                            mb={3}
                            color="textSecondary"
                            textTransform="uppercase"
                            >
                            {'Partner'}
                        </Typography>
                        <Box my={4}>
                            <Box fontSize="50px" mt={5} fontWeight="600">
                                {'Free'}
                            </Box>
                        </Box>
                        <Box mt={3}>
                            <List>
                                <ListItem disableGutters>
                                    <ListItemIcon sx={{ color: 'primary.main', minWidth: '32px' }}>
                                        <IconCheck width={18} />
                                    </ListItemIcon>
                                    <ListItemText>{'200 requests per day'}</ListItemText>
                                </ListItem>
                            </List>
                        </Box>

                        <Button
                            sx={{ width: '100%', mt: 3 }}
                            variant="contained"
                            size="large"
                            onClick={() => handleListItemClick('Partner')}
                            color="secondary">
                        {'CONTACT US'}
                        </Button>
                            {
                                hasShowEmail?
                                    (<CustomContent>
                                    <p>  
                                        We invite partners to join us in this journey by integrating our APIs into their platforms. Please reach out to us at 
                                        <Link key="Email" component="a" href="mailto:info@sorobanexp.com"> info@sorobanexp.com</Link>.
                                    </p>
                                </CustomContent>): (<></>)
                            }
   
                    </CardContent>
                    
                </BlankCard>
            </Grid>            
        </Grid>
    </PageContainer>
    </>
  );
};

export default Membership;
