import React, { useEffect } from 'react';
import {
  Box,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { fetchApiDoc } from 'src/store/app/developer/ApiDocSlice';
import { useDispatch, useSelector } from 'src/store/Store';
import GettingStarted from './introduction/GettingStarted';
import ContractCallback from './contract/ContractCallback';
import ContractOverview from './contract/ContractOverview';
import ContractVerification from './contract/ContractVerification';
import Endpoint from './introduction/Endpoint';
import { isAuthorized } from 'src/utils/authHelper';
import { useNavigate} from 'react-router-dom';
import SupportedEnvironments from './introduction/SupportedEnvironments';
import Membership from './introduction/Membership';


const ApiDocContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(fetchApiDoc(0));
  }, [dispatch]);

  const apiId = useSelector((state) => state.apiDocReducer.apiId);
  const page = useSelector((state) => state.apiDocReducer.page);
  const title = useSelector((state) => state.apiDocReducer.title);

  const loadApiDocContent = () => {
    if('general-getting-started'===page || apiId===0) {
      return (<GettingStarted />)
    } else if('general-get-api-key'===page) {
      if(isAuthorized()) {
        navigate("/developer/api/apiToken");
      } else {
        navigate("/auth/login");
      }
    } else if('general-endpoint'===page) {
      return (<Endpoint />)
    } else if('contract-overview'===page) {
      return (<ContractOverview />)
    } else if('contract-verification'===page) {
      return (<ContractVerification />)
    } else if('contract-callback'===page) {
      return (<ContractCallback />)
    } else if('general-supported-environments'===page) {
      return (<SupportedEnvironments />)
    } else if('general-membership'===page) {
      return (<Membership />)
    } else {
      return (<GettingStarted />)
    }
};


  return (
    <Box>
      {/* ------------------------------------------- */}
      {/* Header Part */}
      <Stack direction="row" justifyContent="space-between" ml={5} mt={3} pb={3}>
          <Typography variant="h4"> {title?title: "Getting started"}</Typography>
      </Stack>
      {/* ------------------------------------------- */}
      {/* <Box sx={{ textAlign: 'center', fontSize: '24px', mt: 2 }}>{title}</Box> */}

      <Divider />
      <div className="col-xs-12 divider-10"/>
      {/* ------------------------------------------- */}
      {/* Edit notes */}
      {/* ------------------------------------------- */}
     <div>{loadApiDocContent()}</div>
    </Box>
  );
};


export default ApiDocContent;
