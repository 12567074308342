import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    trades: any[];
    trade?: any;
}
const initialState = {
    trades: [],
    trade: {},
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const TradeSlice = createSlice({
    name: 'Trade',
    initialState,
    reducers: {
      getTrades: (state: StateType, action) => {
        state.trades = action.payload.result.trades;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getTrade: (state: StateType, action) => {
        state.trade = action.payload.result.trade;
      },
    },
});
export const fetchBlockchainTrades = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getTrades+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getTrades(response.data));
    } catch (err) {
      throw new Error();
    }
};

export const fetchBlockchainTrade = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getTrade+"?id="+id}`);
    dispatch(getTrade(response.data));
  } catch (err) {

    throw new Error();
  }
};

export const fetchBlockchainTradesByLiquidityPool = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLiquidityPoolTrades+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getTrades(response.data));
  } catch (err) {
    throw new Error();
  }
};

export const fetchBlockchainTradesByAccount = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAccountTrades+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getTrades(response.data));
  } catch (err) {
    throw new Error();
  }
};

export const { getTrades, getTrade} = TradeSlice.actions;

export default TradeSlice.reducer;