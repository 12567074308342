import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    effects: any[];
    effect?: any;
}
const initialState = {
    effects: [],
    effect: {},
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const EffectSlice = createSlice({
    name: 'Effect',
    initialState,
    reducers: {
      getEffects: (state: StateType, action) => {
        state.effects = action.payload.result.effects;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getEffect: (state: StateType, action) => {
        state.effect = action.payload.result.effect;
      }
    },
});
export const fetchBlockchainEffects = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getEffects+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getEffects(response.data));
    } catch (err) {
      throw new Error();
    }
};

export const fetchBlockchainEffect = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getEffect+"?id="+id}`);
    dispatch(getEffect(response.data));
  } catch (err) {

    throw new Error();
  }
};

export const fetchBlockchainEffectsByLedger = (ledgerId: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLedgerEffects+"?ledgerId="+ ledgerId + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getEffects(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainEffectsByTxn = (hash: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getTxnEffects+"?txnHash="+ hash + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getEffects(response.data));
  } catch (err) {
    throw new Error();
  }
};

export const fetchBlockchainEffectsByOperation = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getOperationEffects+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getEffects(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainEffectsByLiquidityPool = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLiquidityPoolEffects+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getEffects(response.data));
  } catch (err) {
    throw new Error();
  }
};

export const fetchBlockchainEffectsByAccount = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAccountEffects+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getEffects(response.data));
  } catch (err) {
    throw new Error();
  }
};



export const { getEffects,  getEffect} = EffectSlice.actions;

export default EffectSlice.reducer;