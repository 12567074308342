import React from 'react';
import ApiKey from 'src/components/app/developer/ApiKey';
import AuthPageContainer from 'src/components/container/AuthPageContainer';

// import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';

const DeveloperApiPage = () => {
  return (
    <>
      <AuthPageContainer title="API Key" description="API Key">
        <div className="col-xs-12 divider-10"/>
        <ApiKey/>
      </AuthPageContainer>
    </>
  );
};

export default DeveloperApiPage;
