import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import DeveloperApiPage from 'src/views/developer/DeveloperApiDoc';
import UpdatePassword from 'src/views/auth/UpdatePassword';
import DeveloperApi from 'src/views/developer/DeveloperApi';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));

/*****Pages***** */
/*****Blockchain***** */
const BlockchainDashboard = Loadable(lazy(() => import('src/views/dashboard/BlockchainDashboard')));
const LedgersPage = Loadable(lazy(() => import('src/views/blockchain/ledger/Ledgers')));
const LedgerPage = Loadable(lazy(() => import('src/views/blockchain/ledger/Ledger')));
const ResourceKnowledge = Loadable(lazy(() => import('src/views/resource/ResourceKnowledge')));
const TransactionsPage = Loadable(lazy(() => import('src/views/blockchain/tx/Transactions')));
const TransactionPage = Loadable(lazy(() => import('src/views/blockchain/tx/Transaction')));
const OperationsPage = Loadable(lazy(() => import('src/views/blockchain/operation/Operations')));
const OperationPage = Loadable(lazy(() => import('src/views/blockchain/operation/Operation')));
const EffectsPage = Loadable(lazy(() => import('src/views/blockchain/effect/Efftects')));
const EffectPage = Loadable(lazy(() => import('src/views/blockchain/effect/Effect')));
const PaymentsPage = Loadable(lazy(() => import('src/views/blockchain/payment/Payments')));
const PaymentPage = Loadable(lazy(() => import('src/views/blockchain/payment/Payment')));
const TradesPage = Loadable(lazy(() => import('src/views/blockchain/trade/Trades')));
const TradePage = Loadable(lazy(() => import('src/views/blockchain/trade/Trade')));
const LiquidityPoolsPage = Loadable(lazy(() => import('src/views/blockchain/lp/LiquidityPools')));
const LiquidityPoolPage = Loadable(lazy(() => import('src/views/blockchain/lp/LiquidityPool')));
const ExchangesPage = Loadable(lazy(() => import('src/views/blockchain/Exchanges')));
const AssetsPage = Loadable(lazy(() => import('src/views/blockchain/asset/Assets')));
const AssetPage = Loadable(lazy(() => import('src/views/blockchain/asset/Asset')));
const AccountsPage = Loadable(lazy(() => import('src/views/blockchain/account/Accounts')));
const AccountPage = Loadable(lazy(() => import('src/views/blockchain/account/Account')));
const OffersPage = Loadable(lazy(() => import('src/views/blockchain/offer/Offers')));
const OfferPage = Loadable(lazy(() => import('src/views/blockchain/offer/Offer')));
const ContractPage = Loadable(lazy(() => import('src/views/blockchain/contract/Contract')));

/*****Contract***** */
const ContractDashboardPage = Loadable(lazy(() => import('src/views/dashboard/ContractDashboard')));
const VerifiedContractDashboard = Loadable(lazy(() => import('src/views/contract/VerifiedContractDashboard')));
const ContractProcessPage = Loadable(lazy(() => import('src/views/contract/ContractProcess')));
const ContractVerifyPage = Loadable(lazy(() => import('src/views/contract/ContractVerify')));
const VerifiedContractDetailPage = Loadable(lazy(() => import('src/views/contract/ContractVerifiedDetail')));
/*****Auth***** */
const SignInView = Loadable(lazy(() => import('src/views/auth/SignIn')));
const SignUpView = Loadable(lazy(() => import('src/views/auth/SignUpView')));
const ForgotPassword = Loadable(lazy(() => import('src/views/auth/ForgotPassword')));
const ConfirmPassword = Loadable(lazy(() => import('src/views/auth/ConfirmPassword')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/contract/dashboard" /> },
      { path: '/blockchain/Dashboard', exact: true, element: <BlockchainDashboard /> },
      { path: '/contract/dashboard', element: <ContractDashboardPage /> },
      { path: '/contract/verify', element: <ContractVerifyPage /> },
      { path: '/contract/verifiedList', element: <VerifiedContractDashboard /> },
      { path: '/contract/process/:id', element: <ContractProcessPage /> },
      { path: '/contract/verify/:id', element: <VerifiedContractDetailPage /> },

      { path: '/auth/login', element: <SignInView /> },
      { path: '/auth/register', element: <SignUpView /> },
      { path: '/auth/forgetPassword', element: <ForgotPassword /> },
      { path: '/auth/confirmPassword', element: <ConfirmPassword /> },
      { path: '/auth/updatePassword', element: <UpdatePassword /> },

      { path: '/blockchain/ledger/ledgers', element: <LedgersPage /> },
      { path: '/blockchain/ledger/ledger/:sequence', element: <LedgerPage /> },
      { path: '/blockchain/tx/transactions', element: <TransactionsPage /> },
      { path: '/blockchain/tx/tx/:hash', element: <TransactionPage /> },
      { path: '/blockchain/op/operations', element: <OperationsPage /> },
      { path: '/blockchain/op/operation/:id', element: <OperationPage /> },
      { path: '/blockchain/lp/liquidityPools', element: <LiquidityPoolsPage /> },
      { path: '/blockchain/lp/liquidityPool/:id', element: <LiquidityPoolPage /> },
      { path: '/blockchain/td/trades', element: <TradesPage /> },
      { path: '/blockchain/td/trade/:id', element: <TradePage /> },
      { path: '/blockchain/pm/payments', element: <PaymentsPage /> },
      { path: '/blockchain/pm/payment/:id', element: <PaymentPage /> },
      { path: '/blockchain/ef/effects', element: <EffectsPage /> },
      { path: '/blockchain/ef/effect/:id', element: <EffectPage /> },
      { path: '/blockchain/ac/accounts', element: <AccountsPage /> },
      { path: '/blockchain/ac/account/:id', element: <AccountPage /> },
      { path: '/blockchain/as/assets', element: <AssetsPage /> },
      { path: '/blockchain/as/asset', element: <AssetPage /> },
      { path: '/blockchain/of/offers', element: <OffersPage /> },
      { path: '/blockchain/of/offer/:id', element: <OfferPage /> },
      { path: '/blockchain/ct/contract/:id', element: <ContractPage /> },
      
      { path: '/blockchain/ex/exchanges', element: <ExchangesPage /> },
      { path: '/developer/api/docs', element: <DeveloperApiPage /> },
      { path: '/developer/api/apiToken', element: <DeveloperApi /> },
      { path: '/resource/knowledger', element: <ResourceKnowledge /> },    
      { path: '*', element: <Navigate to="/" /> },
    ],
  }
];

export default Router;
