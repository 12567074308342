import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    payments: any[];
    payment?: any;
}
const initialState = {
    payments: [],
    payment: {},
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const PaymentSlice = createSlice({
    name: 'Payment',
    initialState,
    reducers: {
      getPayments: (state: StateType, action) => {
        state.payments = action.payload.result.payments;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getPayment: (state: StateType, action) => {
        state.payment = action.payload.result.payment;
      },
    },
});
export const fetchBlockchainPayments = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getPayments+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getPayments(response.data));
    } catch (err) {
      throw new Error();
    }
};

export const fetchBlockchainPayment = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getPayment+"?id="+id}`);
    dispatch(getPayment(response.data));
  } catch (err) {

    throw new Error();
  }
};

export const fetchBlockchainPaymentsByLedger = (ledgerId: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLedgerPayments+"?ledgerId="+ ledgerId + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getPayments(response.data));
  } catch (err) {
    throw new Error();
  }
};

export const fetchBlockchainPaymentByTxn = (hash: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getTxnPayments+"?txnHash="+ hash + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getPayments(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainPaymentByAccount = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAccountPayments+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getPayments(response.data));
  } catch (err) {
    throw new Error();
  }
};



export const { getPayments, getPayment} = PaymentSlice.actions;

export default PaymentSlice.reducer;