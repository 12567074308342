import axios from 'axios';

const axiosAuthServices = axios.create(
    {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
    }
);
// interceptor for http
axiosAuthServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosAuthServices;
