import React from 'react';
// import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import BlankCard from '../../../../components/shared/BlankCard';
import pageWipImg from 'src/assets/images/misc/page-under-construction-icon.jpg'

const PageUnderConstruction = () => {
  return (
    <>
    <PageContainer title="Developer Api" description="Dashboard">
     <div className="col-xs-12 divider-10"/>
      {/* breadcrumb */}
      {/* <Breadcrumb title="Dashboard" items={BCrumb} /> */}
      {/* end breadcrumb */}
      <BlankCard>
            <img src={pageWipImg} alt={pageWipImg} width={'100%'} />
      </BlankCard>
    </PageContainer>
    </>
  );
};

export default PageUnderConstruction;
