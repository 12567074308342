import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import ContractExplorerReducer from './app/contract/ContractExplorerSlice';
import ContractProcesReducer from './app/contract/ContractProcesSlice';
import ContractVerifyReducer from './app/contract/ContractVerifySlice';
import ApiDocReducer from './app/developer/ApiDocSlice';

import LedgerReducer from './app/blockchain/ledger/LedgerSlice';
import TxReducer from './app/blockchain/tx/TxSlice';
import OperationReducer from './app/blockchain/operation/OperationSlice';
import LpReducer from './app/blockchain/lp/LpSlice';
import EffectReducer from './app/blockchain/effect/EffectSlice';
import AssetReducer from './app/blockchain/asset/AssetSlice';
import PaymentReducer from './app/blockchain/payment/PaymentSlice';
import TradeReducer from './app/blockchain/trade/TradeSlice';
import AccountReducer from './app/blockchain/account/AccountSlice';
import OfferReducer from './app/blockchain/offer/OfferSlice';
import ContractReducer  from './app/blockchain/contract/ContractSlice';

import AuthReducer from './app/auth/AuthSlice';

import { combineReducers } from 'redux';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';


export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    authReducer: AuthReducer,
    contractExplorerReducer: ContractExplorerReducer,
    contractProcesReducer: ContractProcesReducer,
    contractVerifyReducer: ContractVerifyReducer,
    ledgerReducer: LedgerReducer,
    txReducer: TxReducer,
    operationReducer: OperationReducer,
    lpReducer: LpReducer,
    tradeReducer: TradeReducer,
    effectReducer: EffectReducer,
    assetReducer: AssetReducer,
    paymentReducer: PaymentReducer,
    accountReducer: AccountReducer,
    offerReducer: OfferReducer,
    contractReducer: ContractReducer,
    apiDocReducer: ApiDocReducer
  },
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  authReducer: AuthReducer,
  contractExplorerReducer: ContractExplorerReducer,
  contractProcesReducer: ContractProcesReducer,
  contractVerifyReducer: ContractVerifyReducer,
  ledgerReducer: LedgerReducer,
  txReducer: TxReducer,
  operationReducer: OperationReducer,
  lpReducer: LpReducer,
  tradeReducer: TradeReducer,
  effectReducer: EffectReducer,
  assetReducer: AssetReducer,
  paymentReducer: PaymentReducer,
  accountReducer: AccountReducer,
  offerReducer: OfferReducer,
  contractReducer: ContractReducer,
  apiDocReducer: ApiDocReducer

});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
