import { Box } from '@mui/material';
import React from 'react';
import ApiDocContent from 'src/components/app/developer/ApiDocContent';
import ApiDocSidebar from 'src/components/app/developer/ApiDocSidebar';
import PageContainer from 'src/components/container/PageContainer';
import AppCard from 'src/components/shared/AppCard';


const DeveloperApiPage = () => {
  return (
    <>
      <PageContainer title="API Key" description="API Key">
        <div className="col-xs-12 divider-10"/>
        <AppCard>
            <ApiDocSidebar/>
            <Box flexGrow={1}>
              <ApiDocContent />
            </Box>
        </AppCard>
      </PageContainer>
    </>
  );
};

export default DeveloperApiPage;
