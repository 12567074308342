import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineOppositeContentClasses, } from '@mui/lab';
import { Box,  Button,  Dialog,  DialogActions,  DialogContent, Divider, Grid, Stack, Typography} from '@mui/material';
import { IconArrowRight } from '@tabler/icons';
import React  from 'react';
import PageContainer from 'src/components/container/PageContainer';
import CustomContent from 'src/components/shared/doc/CustomContent';
import flowDiagram from 'src/assets/images/api/flow-diagram.png';


const ContractOverview = () => { 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

return (
<>
  <PageContainer>
      <Grid container spacing={3} marginLeft={2}>
          <Grid item xs={12}>
          <Box>
             {/* ------------------------------------------- */}
              {/* API  Detail page */}
              {/* ------------------------------------------- */}
                <Stack p={2} gap={0} direction="column">
                  <CustomContent>
                    <p>  
                      The multi-tier architectural foundation of the Soroban Contract Explorer emphasizes scalability and modularity to enhance flexibility and performance. 
                      The contract verification process involves multiple steps:
                  </p>
                  </CustomContent>
                  <Box sx={{marginBottom: 6 }}>
                  <Timeline
                        className="theme-timeline"
                        nonce={undefined}
                        onResize={undefined}
                        onResizeCapture={undefined}
                        sx={{
                          p: 0,
                          ml: '-200px',
                          mb: '-40px',
                          [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.5,
                            paddingLeft: 0,
                          },
                        }}
                      >
                        <TimelineItem>
                          <TimelineOppositeContent>Step 1</TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary" variant="outlined" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                              <Typography variant="h6" color="textSecondary" sx={{ marginRight: 10}}>User input required information into the web form</Typography>
                              <Stack p={2} gap={0} direction="row">
                                <Typography variant="subtitle2" color="textSecondary" sx={{ marginRight: 10}}>
                                  Contract ID  
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                GitHub URL
                                </Typography>
                              </Stack>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>Step 2</TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary" variant="outlined" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Stack gap={0} direction="column" sx={{ marginTop: 0 }}>
                                  <Typography variant="h6" color="textSecondary" sx={{ marginRight: 10, marginBottom: 2}}>
                                    Prepare
                                  </Typography>
                                <Box>
                                    <Stack gap={0} direction="row">
                                      <IconArrowRight width={20} height={15}/> 
                                      <Typography variant="h6" color="textSecondary" sx={{ marginLeft: 2,  marginRight: 10, marginBottom: 2}}>
                                        Validates GitHub resource
                                      </Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack gap={0} direction="row">
                                      <IconArrowRight width={20} height={15}/> 
                                      <Typography variant="h6" color="textSecondary" sx={{ marginLeft: 2,  marginRight: 10, marginBottom: 2}}>
                                      Detects deployed contract metadata
                                      </Typography>
                                    </Stack>
                                </Box>
                              </Stack>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>Step 3</TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="primary" variant="outlined" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Stack gap={0} direction="column">
                              <Typography variant="h6" color="textSecondary" sx={{ marginRight: 10, marginBottom: 2}}>
                                Initiates project build in matched soroban environment
                              </Typography>
                              </Stack>
                          </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                          <TimelineOppositeContent>Step 4</TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color="primary" variant="outlined" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                              <Stack gap={0} direction="column" sx={{ marginTop: 0 }}>
                                  <Typography variant="h6" color="textSecondary" sx={{ marginRight: 10, marginBottom: 2}}>
                                    Verify (compare)
                                  </Typography>
                                <Box>
                                    <Stack gap={0} direction="row">
                                      <IconArrowRight width={20} height={15}/> 
                                      <Typography variant="h6" color="textSecondary" sx={{ marginLeft: 2,  marginRight: 10, marginBottom: 2}}>
                                      Wasm hash generated by project build
                                      </Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack gap={0} direction="row">
                                      <IconArrowRight width={20} height={15}/> 
                                      <Typography variant="h6" color="textSecondary" sx={{ marginLeft: 2,  marginRight: 10, marginBottom: 2}}>
                                      Wasm hash existed in the Soroban network
                                      </Typography>
                                    </Stack>
                                </Box>
                              </Stack>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineOppositeContent>Result</TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color="primary" variant="outlined" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>

                              <Stack gap={0} direction="row" sx={{ marginTop: -1 }}>
                                  <TimelineDot color="success" variant="filled"/>
                                  <Typography variant="h6" color="textSecondary" sx={{ marginTop: 1, marginLeft: 1,  marginRight: 1}}>
                                      |
                                  </Typography>
                                  <TimelineDot color="error" variant="filled" />
                                </Stack>
                          </TimelineContent>
                        </TimelineItem>
                  </Timeline>

                  </Box>
                  <Divider/>
                  <CustomContent >
                    <p>  
                    The entire pipeline workflow process is illustrated in the diagram below.
                  </p>
                  </CustomContent>
                </Stack>
            </Box>
          </Grid>
          <Grid container spacing={3} marginLeft={2} marginTop={5} marginBottom={10}>
              <Grid item xs={12} sx={{paddingLeft: 10}}>
                <Grid container spacing={3} marginLeft={5}>
                  <Box
                    component="img"
                    sx={{
                      width: '90%',
                      marginLeft: -10,
                      cursor: 'pointer'
                    }}
                    alt="Flow diagram"
                    src={flowDiagram}
                    onClick={handleOpen}
                  />
                    <Dialog open={open} maxWidth="lg" fullWidth onClose={handleClose}>
                          <DialogContent style={{ width: '100%', maxWidth: 'none' }}>
                              <img src={flowDiagram} alt="Flow Diagram Image" className="app-card-image"/>
                          </DialogContent>
                          <DialogActions>
                            <Button color="error" variant="contained" onClick={handleClose}>
                              Close
                            </Button>
                          </DialogActions>
                    </Dialog>
                </Grid>
              </Grid>
        </Grid>
      </Grid>

  </PageContainer>
  </>
);
};

export default ContractOverview;
