import { Box } from "@mui/material";


type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
    sx?: any;
}
const CustomContent = ({ children, sx, className }: Props) => {
  return (
    <>
        <Box display="flex" sx={{ marginRight: 8, wordBreak: 'break-word', marginBottom: 2, sx  }} 
            alignItems="center" justifyContent="space-between" className={ `pre-wrap-text ${className}` }>
           <>
           {children}
           </> 
        </Box>
    </>
  );
};

export default CustomContent;