import React, { useEffect } from 'react';
import { Alert, Box, Button, Card, CardContent, Grid, Stack, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import CustomTextField from '../shared/form/CustomTextField';
import CustomFormLabel from '../shared/form/CustomFormLabel';
import { submitUpdatePasswordForm, reset } from 'src/store/app/auth/AuthSlice';
import { dispatch, useSelector } from 'src/store/Store';
import { LoadingButton } from '@mui/lab';
import { IconTrash } from '@tabler/icons';
import LogoBox from 'src/layouts/full/shared/logo/LogoBox';
import PageContainer from '../container/PageContainer';

const AuthUpdatePassword = () => {

  const theme = useTheme();
  const borderColor = theme.palette.grey[100];
  const success = useSelector((state) => state.authReducer.success);
  const message = useSelector((state) => state.authReducer.message);
  const loading = useSelector((state) => state.authReducer.loading);

  const validationSchema = yup.object({
    previousPassword: yup
    .string()
    .matches(
      /^(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Number and One Special Case Character"
    )
    .required('Password is required'),
    proposedPassword: yup
    .string()
    .matches(
      /^(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Number and One Special Case Character"
    )
    .required('Password is required'),
  });

  useEffect(() => {
    dispatch(reset());
  }, []);

  const formik = useFormik({
    initialValues: {
        previousPassword: '',
        proposedPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        submitUpdatePassword(values.previousPassword, values.proposedPassword);
    },
  });
  const submitUpdatePassword = (previousPassword: string, proposedPassword: string) => {
    dispatch(submitUpdatePasswordForm(previousPassword, proposedPassword));
  }

  return (
  <>
     <PageContainer>
        <Grid container spacing={0} sx={{ overflowX: 'hidden', marginTop: 10 }}>
            <Grid item xs={3} sm={3} lg={3}/>
            <Grid item xs={6} sm={6} lg={6}>
                <Card  sx={{ padding: 5, border: `2px solid ${borderColor}`}}
                        elevation={9}
                        variant={'outlined'}>
                    <div>
                            { (!success && !loading ) ? 
                            (<Alert severity="error"> {message}</Alert>): 
                            (message.length>0?(<Alert severity="success"> {message}</Alert>): '')}   
                    </div>
                    <LogoBox />
                    <CardContent sx={{p: "30px"}}>

                            <form onSubmit={formik.handleSubmit}>
                            <Stack mt={4} spacing={2}>
                                    <Box>
                                        <CustomFormLabel htmlFor="password">Previous Password</CustomFormLabel>
                                        <CustomTextField
                                            fullWidth
                                            placeholder="password"
                                            id="previousPassword"
                                            name="previousPassword"
                                            type="password"
                                            value={formik.values.previousPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.previousPassword && Boolean(formik.errors.previousPassword)}
                                            helperText={formik.touched.previousPassword && formik.errors.previousPassword}
                                        />
                                    </Box>
                                    <Box>
                                            <CustomFormLabel htmlFor="password">New Password</CustomFormLabel>
                                            <CustomTextField
                                                fullWidth
                                                placeholder="password"
                                                id="proposedPassword"
                                                name="proposedPassword"
                                                type="password"
                                                value={formik.values.proposedPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.proposedPassword && Boolean(formik.errors.proposedPassword)}
                                                helperText={formik.touched.proposedPassword && formik.errors.proposedPassword}
                                            />
                                    </Box>
                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center">
                                    {!loading?(                      
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            disabled={!(formik.isValid && formik.dirty)}
                                        >
                                        Submit
                                        </Button>
                                        ):
                                        (
                                        <LoadingButton loading
                                            variant="contained"
                                            color="secondary"
                                            endIcon={<IconTrash width={18} />}
                                            >
                                            Submit
                                            </LoadingButton >
                                        )
                                        }
                                    </Stack>
                            </Stack>
                            </form>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={3} sm={3} lg={3}/>
        </Grid>
    </PageContainer>
    </>
  );

};

export default AuthUpdatePassword;
