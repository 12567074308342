import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    txs: any[];
    tx?: any;
    operations: any[];
}
const initialState = {
    txs: [],
    tx: {},
    operations: [],
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const TxSlice = createSlice({
    name: 'Blockchain',
    initialState,
    reducers: {
      getTxs: (state: StateType, action) => {
        state.txs = action.payload.result.transactions;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;
      },
      getDashboardTxs: (state: StateType, action) => {
        state.txs = action.payload.result.transactions;
      },
      getTx: (state: StateType, action) => {
        state.tx = action.payload.result.tx;
        state.operations = action.payload.result.operations;
      },
    },
});
export const fetchBlockchainTxs = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getTxs+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getTxs(response.data));
    } catch (err) {
      throw new Error();
    }
};
export const fetchDashboardTxs = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getDashboardTxs}`);
    dispatch(getDashboardTxs(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainTx = (hash: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getTx+"?txnHash="+hash}`);
    dispatch(getTx(response.data));
  } catch (err) {

    throw new Error();
  }
};

export const fetchBlockchainTxsByLedger = (ledgerId: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLedgerTxns+"?ledgerId="+ ledgerId + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getTxs(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainTxsByLiquidityPool = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLiquidityPoolTxns+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getTxs(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchBlockchainTxsByAccount = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAccountTxns+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getTxs(response.data));
  } catch (err) {
    throw new Error();
  }
};


export const { getTxs, getDashboardTxs, getTx} = TxSlice.actions;

export default TxSlice.reducer;