import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    result: any[];
}
const initialState = {
    result: [],
  };

export const ContractVerifySlice = createSlice({
    name: 'ContractExplorer',
    initialState,
    reducers: {
        verifyContractForm: (state: StateType, action) => {
        state.result = action.payload.result;
      },
    },
});

export const submitContractForm = (contractId: any, source: any, platform: any, network: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post(`${ConfigData.URL.processDetail}`,{ data: {
        "contractId": contractId,
        "network": network,
        "platform": platform
      }});
     dispatch(verifyContractForm(response.data));

  } catch (err) {
    throw new Error();
  }

};

export const { verifyContractForm } = ContractVerifySlice.actions;

export default ContractVerifySlice.reducer;