import { Box } from '@mui/material';
import { FC } from 'react';

import sorobanexpImg from 'src/assets/images/logos/sorobanexp-icon-1.png';

const LogoBox: FC = () => {

 return (
    <Box display="flex" alignItems="center" justifyContent="center"  sx={{
        p: '5px 5px 5px',
        marginBottom: '2px',
        position: 'relative',
        overflow: 'hidden',
      }}>
        <img src={sorobanexpImg} alt={sorobanexpImg} width={'165px'} />
    </Box>
  );
};

export default LogoBox;
