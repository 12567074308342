import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React  from 'react';
import PageContainer from 'src/components/container/PageContainer';
import CustomContent from 'src/components/shared/doc/CustomContent';
import 'swagger-ui-react/swagger-ui.css';
import * as verifycontract from "src/assets/api/verifycontract.json";
import SwaggerUI from 'swagger-ui-react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const ContractVerification = () => { 

  const curlCommand =
     `curl --location 'https://testopenapi.sorobanexp.com/api/verifycontract'  \\
      -H 'Content-Type: application/json' \\
      -H 'Authorization: Bearer eyJhbGciOeweIUzI1NiIsIn0qw9qwJ9.eyJ1c2VsxzmFtZSI6I' \\
      -d '{
        "contractId": "CALRUGL74XDHJOY6UCMR4CNXOVORNNQY62EVUAJ7D3ROTJIYOYGYUKHA",
        "source": "https://github.com/stellar/soroban-examples/tree/v20.0.0/cross_contract/contract_a",
        "callback": "https://api.yoursite/xxxx/xxxxxx"
      }'`;
      const customLineNumberStyle = {
        color: '#fff', // Set the color for line numbers
      };


return (
  <>
 <PageContainer>
      <Grid container marginLeft={2} paddingRight={10}>
        <Grid item xs={12}>
          <Box sx={{marginRight: 10 }}>
             {/* ------------------------------------------- */}
              {/* API  Detail page */}
              {/* ------------------------------------------- */}
            <Stack p={2} gap={0}>
              <CustomContent>
                <p>  
                The Contract Verification API enables API clients to submit contract verification requests, and the Soroban Explorer platform processes these requests, returning the results. 
                In the overview session, we learned that the verification process involves several steps to validate the requested contract. As an immediate response, the Verification API provides initial response data. To initiate a request, an authorization header token, contract ID, GitHub URL, and callback URL are required. 
                The Soroban Explorer platform communicates the process result by invoking the callback URL. The API template is structured as follows:
               </p>
              </CustomContent>
              <Divider/>
              <Box>
                  <Typography variant="h5">Example</Typography>

                  <SyntaxHighlighter language="bash" style={monokaiSublime} 
                    showLineNumbers
                    lineNumberStyle={customLineNumberStyle}
                   >
                    {curlCommand}
                  </SyntaxHighlighter>
              </Box>
              <Divider/>
              <Box>
                <SwaggerUI  spec={verifycontract} docExpansion={'full'} />
              </Box>
            </Stack>
          </Box>
          </Grid>
      </Grid>
  </PageContainer>
    </>
  );
};

export default ContractVerification;
