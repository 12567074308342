import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    page: number;
    pageSize: number;
    total: number;
    result: any[];
}
const initialState = {
    result: [],
    page: 0,
    pageSize: 10,
    total: 0,
  };

export const ContractExplorerSlice = createSlice({
    name: 'ContractExplorer',
    initialState,
    reducers: {
      getDashboardContracts: (state: StateType, action) => {
        state.result = action.payload.result;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.total = action.payload.total;
      },
      getSearchDashboardContracts: (state: StateType, action) => {
        state.result = action.payload.result;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.total = action.payload.total;
      },
    },
});
 /**
 *
 * search dashboard function.
 * @param searchTerm - search text from search input.
 * @returns search results.
 */
export const searchDashboardContract = (searchTerm: string, page: number, pageSize: number) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.searchContractDashboard}?page=${page}&pageSize=${pageSize}`, {
        params: {
            search: searchTerm
          }
      });
       dispatch(getSearchDashboardContracts(response.data));
    } catch (err) {
      throw new Error();
    }
  
};

export const fetchDashboardContracts = (page: any, pageSize: any) => async (dispatch: AppDispatch) => {
  try {
    console.log("fetchDashboardContracts", page, pageSize);
    const response = await axios.get(`${ConfigData.URL.loadContractDashboard}?page=${page}&pageSize=${pageSize}`);
     dispatch(getDashboardContracts(response.data));

  } catch (err) {
    throw new Error();
  }

};

export const { getDashboardContracts, getSearchDashboardContracts } = ContractExplorerSlice.actions;

export default ContractExplorerSlice.reducer;