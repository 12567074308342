import React from 'react';
import { Helmet } from 'react-helmet';
import { isAuthorized } from 'src/utils/authHelper';
import { submitLogoutForm } from 'src/store/app/auth/AuthSlice';
import { dispatch } from 'src/store/Store';

type Props = {
  description?: string;
  children: JSX.Element | JSX.Element[];
  title?: string;
};

const AuthPageContainer = ({ title, description, children} : Props) => {

    if(!isAuthorized()) {
        dispatch(submitLogoutForm());
    } 

    return (
        <>
        {isAuthorized()?(
            <div>
            <Helmet>
              <title>{title}</title>
              <meta name="description" content={description} />
            </Helmet>
            {children}
          </div>
         ): (<></>)
        }
      </>
    );
    
}


export default AuthPageContainer;
