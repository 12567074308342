import React, { useEffect, useState }  from 'react';
import { isAuthorized } from 'src/utils/authHelper';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
    IconButton,
    InputAdornment,
    Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PageUnderConstruction from 'src/layouts/full/shared/pages/PageUnderConstruction';
import DashboardCard from 'src/components/shared/DashboardCard';
import CustomOutlinedInput from 'src/components/shared/form/CustomOutlinedInput';
import { IconCopy, IconEye, IconEyeOff } from '@tabler/icons';
import BlankCard from 'src/components/shared/BlankCard';
import CustomFormLabel from 'src/components/shared/form/CustomFormLabel';
import CustomTextField from 'src/components/shared/form/CustomTextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import authAxios from 'src/utils/auth-axios';
import { ConfigData } from 'src/utils/conf';

const ApiKey = () => { 
  const [showApiKey, setShowApiKey] = React.useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [token, setToken] = useState('');
  const [shortToken, setShortToken] = useState('');
  const [expiredIn, setExpiredIn] = useState('');
  const [isExp, setIsExp] = useState(true);
  const [created, setCreated] = useState('');

  const handleClickShowApiKey = () => setShowApiKey((show) => !show);

  const handleMouseDownApiKey = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const submitApiTokenGenerationForm = async (password: string) => {
    try {
      const response = await authAxios.post(`${ConfigData.URL.generateApiToken}`,{ 
        password: password
      });
      const success = response.data.success;
      if (success) {
        await fetchApiToken();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchApiToken = async () => {
    try {
      const response = await authAxios.get(`${ConfigData.URL.getGenerateApiToken}`);
      const success = response.data.success;
      if (success) {
        setToken(response.data.token);
        setShortToken(response.data.token_short);
        setExpiredIn(response.data.expiredIn?response.data.expiredIn.exp: '');
        setIsExp(response.data.expiredIn?response.data.expiredIn.isExp: true);
        setCreated(response.data.created_date);
      }

    } catch (err) {
      console.log(err);
    }
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .matches(
        /^(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Number and One Special Case Character"
      )
      .required('Password is required'),
  });
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitApiTokenGeneration(values.password);
      formik.resetForm()
    },
  });
  const handleCopy = () => {
    navigator.clipboard.writeText(token)
  };
  useEffect(() => {
    fetchApiToken();
    
  }, []);

  const submitApiTokenGeneration = (password: any) => {
    submitApiTokenGenerationForm(password);
    setOpen(false);
  }

return (
  <>
     <DashboardCard title="API Key">
    {isAuthorized()?
        (
          
          <Grid container>
              <Grid item xs={12} >
              <BlankCard>
                <Stack sx={{ marginBottom: 2}}>
                    <Box sx={{ marginLeft : 10, marginTop: 2}}>
                      <Button
                            variant="outlined"
                            size="large"
                            onClick={() => handleOpen()}
                            color="secondary">
                            Generate API Token
                      </Button>
      
                    <Dialog open={open} maxWidth="lg" fullWidth onClose={handleClose}>
                          <DialogContent style={{ width: '100%', maxWidth: 'none' }}>
                            <Stack>
                            <form onSubmit={formik.handleSubmit}>
                              <Box marginBottom={5}>
                                  <CustomFormLabel htmlFor="password">Password</CustomFormLabel>
                                  <CustomTextField
                                    fullWidth
                                    placeholder="password"
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                  />
                              </Box>
                                <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      disabled={!(formik.isValid && formik.dirty)}
                                    >
                                      Submit
                                    </Button>
                                    <Button color="error" variant="contained" onClick={handleClose}>
                                      Close
                                  </Button>
                                </Stack>
                              </form>
                            </Stack>
                          </DialogContent>
                    </Dialog>
                  </Box>
                </Stack>
              
              </BlankCard>
              </Grid>
              <Divider/>
              <Grid item xs={12} lg={12}  display="flex" alignItems="stretch" marginTop={6}>
                <TableContainer>
                  <Table sx={{  width: '100%', maxWidth: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '40%' }}>Token</TableCell>
                            <TableCell sx={{ width: '30%' }}>Issue At</TableCell>
                            <TableCell sx={{ width: '30%' }}>Expire Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                            <TableCell sx={{ width: '40%' }}>
                              <Stack justifyContent="center" direction="row" alignItems="center" my={2}>
                                <CustomOutlinedInput
                                    type={showApiKey ? 'text' : 'password'}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle apikey visibility"
                                          onClick={handleClickShowApiKey}
                                          onMouseDown={handleMouseDownApiKey}
                                          edge="end"
                                        >
                                          {showApiKey ? <IconEyeOff size="20" /> : <IconEye size="20" />}
                                        </IconButton>
                                      </InputAdornment>
                                      }
                                      placeholder="apikey"
                                      value={shortToken}
                                      fullWidth
                                    />
                                      <InputAdornment 
                                  style={{ marginLeft: '2px', marginTop: '2px', marginRight: '5px', cursor: 'pointer' }} 
                                  position="end" onClick={() => handleCopy()}>
                                    <IconCopy size="20" />
                                  </InputAdornment>
                                </Stack>
                            </TableCell>
                            <TableCell sx={{ width: '30%' }}>
                                <Typography variant="subtitle2">{created}</Typography>
                            </TableCell>
                            <TableCell sx={{ width: '30%' }}>
                      
                                 {!isExp?                                 
                                      (<Typography sx={{ color: "#43a047" }} variant="subtitle2">
                                        {expiredIn}
                                      </Typography>):
                                        (<Typography sx={{ color: "#ff7043" }} variant="subtitle2">
                                           {expiredIn}
                                        </Typography>)
                                 }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              
              </Grid>
          </Grid>
        )
        : ( <PageUnderConstruction/>)}
       </DashboardCard>
    </>
  );
};

export default ApiKey;
