import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    result: any;
}
const initialState = {
    result: {},
  };

export const ContractProcesSlice = createSlice({
    name: 'ContractExplorer',
    initialState,
    reducers: {
        loadContractProcessDetail: (state: StateType, action) => {
        state.result = action.payload.result;
      },
    },
});

export const fetchContractProcessDetail = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post(`${ConfigData.URL.processDetail}`,{"exploreId": id});
     dispatch(loadContractProcessDetail(response.data));

  } catch (err) {
    throw new Error();
  }

};

export const { loadContractProcessDetail } = ContractProcesSlice.actions;

export default ContractProcesSlice.reducer;