import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import authAxios from 'src/utils/auth-axios';
import axios from 'src/utils/axios';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    page: string;
    apiId: number;
    title: string;
    result: any[];
    token: string;
    expiredIn: any,
    created: string,
    loading: boolean;
}
const initialState = {
    page: 'general-introduction',
    apiId: 0,
    title: 'Getting started',
    result: [],
    expiredIn: {},
    token: '',
    loading: false,
    created: ''
  };

export const ApiDocSlice = createSlice({
    name: 'ApiDoc',
    initialState,
    reducers: {
       toggleLoading: (state: StateType, action) => {
        state.loading = action.payload;
       },
        getApiDoc: (state: StateType, action) => {
            state.apiId = action.payload.apiId;
            state.page = action.payload.page;
            state.title = action.payload.title;
        },
        selectApi: (state: StateType, action) => {
            state.apiId = action.payload.apiId;
            state.page = action.payload.page;
            state.title = action.payload.title;
        },
        getSupporedEnv: (state: StateType, action) => {
          state.result = action.payload.result;
        },
        getApiTokenGeneration: (state: StateType, action) => {
          state.expiredIn = action.payload.expiredIn;
          state.token = action.payload.token;
          state.created = action.payload.created_date;
        },
        getApiToken: (state: StateType, action) => {
          state.expiredIn = action.payload.expiredIn;
          state.token = action.payload.token;
          state.created = action.payload.created_date;
        }
    },
});

export const fetchApiDoc = (apiId: number) => async (dispatch: AppDispatch) => {
    try {
       dispatch(getApiDoc({apiId: apiId}));
    } catch (err) {
      throw new Error();
    }
  };

export const selectApiDoc = (apiId: number, page: string, title: string) => async (dispatch: AppDispatch) => {
    try {
      console.log("SelectApiDoc", apiId, "page", page);
       dispatch(selectApi({apiId: apiId, page: page, title: title}));
    } catch (err) {
      throw new Error();
    }
  };

export const fetchSupporedEnv= () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getSupporedEnv}`);
      dispatch(getSupporedEnv(response.data));
  } catch (err) {
    throw new Error();
  }
};

export const submitApiTokenGenerationForm = (password: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleLoading(true));
    const response = await authAxios.post(`${ConfigData.URL.generateApiToken}`,{ 
      password: password
    });
    dispatch(getApiTokenGeneration(response.data));
    dispatch(toggleLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(toggleLoading(false));
  }
};

export const fetchApiToken = () => async (dispatch: AppDispatch) => {
  try {
    const response = await authAxios.get(`${ConfigData.URL.getGenerateApiToken}`);
    dispatch(getApiToken(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const { getApiDoc, selectApi , getSupporedEnv, getApiTokenGeneration, toggleLoading, getApiToken } = ApiDocSlice.actions;

export default ApiDocSlice.reducer;