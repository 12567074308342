/**
 * define basic config
 */

export class ConfigData {

    public static SERVICE_URL_BASE=process.env.REACT_APP_API_URL;
    public static REACT_APP_DEFAULT_NETWORK =process.env.REACT_APP_DEFAULT_NETWORK;
    public static REACT_APP_CONTRACT_ENABLE =process.env.REACT_APP_CONTRACT_ENABLE;
    public static REACT_APP_SOCKET_URL =process.env.REACT_APP_SOCKET_URL || 'http://localhost:8000';

    public static NETWORK_PUBLIC = 'Public';
    public static NETWORK_TEST = 'Testnet';
    public static NETWORK_FUTURE = 'Future';
    public static NETWORK_LOCAL = 'Local';
    public static NETWORKS = [ConfigData.NETWORK_PUBLIC, ConfigData.NETWORK_TEST];
    //public static NETWORKS = [ConfigData.NETWORK_TEST, ConfigData.NETWORK_FUTURE];
    //public static NETWORKS = [ConfigData.NETWORK_TEST];
    //public static NETWORKS = [ConfigData.NETWORK_PUBLIC, ConfigData.NETWORK_TEST, ConfigData.NETWORK_FUTURE];
    //public static NETWORKS = [NETWORK_LOCAL];
    public static SITE_KEY = process.env.REACT_APP_BASE_ReCAPTCHA_CONFIG_KEY;
    public static SERVICE_URL_AUTH_API_BASE= ConfigData.SERVICE_URL_BASE+ "api/auth/";
    public static SERVICE_URL_EXPLORER_API_BASE= ConfigData.SERVICE_URL_BASE+ "api/explorer/";
    public static SERVICE_URL_PROCESS_API_BASE= ConfigData.SERVICE_URL_BASE+ "api/process/";
    public static SERVICE_URL_IDE_API_BASE= ConfigData.SERVICE_URL_BASE+ "api/ide/";
    public static SERVICE_URL_SOROBAN_API_BASE= ConfigData.SERVICE_URL_BASE+ "api/soroban/";
    public static SERVICE_URL_LEDGE_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "ledger/";
    public static SERVICE_URL_TX_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "tx/";
    public static SERVICE_URL_OPERATION_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "operation/";
    public static SERVICE_URL_LP_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "lp/";
    public static SERVICE_URL_EFFECT_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "effect/";
    public static SERVICE_URL_PAYMENT_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "payment/";
    public static SERVICE_URL_TRADE_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "trade/";
    public static SERVICE_URL_ASSET_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "asset/";
    public static SERVICE_URL_ACCOUNT_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "account/";
    public static SERVICE_URL_OFFER_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "offer/";
    public static SERVICE_URL_CONTRACT_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "contract/";
    public static SERVICE_URL_SEARCH_API_BASE= ConfigData.SERVICE_URL_SOROBAN_API_BASE+ "search/";


    public static URL = {

         loadContractDashboard: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"getExplorerData",
         searchContractDashboard: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"searchExplorerData",
         loadContractVerifiedDashboard: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"getVerifiedExplorerData",
         searchtVerifiedContractDashboard: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"searchVerifiedExplorerData",
         verify: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"verify",
         verifyWithFile: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"verifyWithFile",
         verifyContract: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"verifyContract",
         channel: ConfigData.SERVICE_URL_BASE,
         getSupporedEnv: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"getSupporedEnv",
         processDetail: ConfigData.SERVICE_URL_PROCESS_API_BASE+"getProcessDetail",
         getContractWasm: ConfigData.SERVICE_URL_PROCESS_API_BASE+"getContractWasm",
         loadWasmFile: ConfigData.SERVICE_URL_PROCESS_API_BASE+"loadWasmFile",
         getBuildProcessLog: ConfigData.SERVICE_URL_PROCESS_API_BASE+"getBuildProcessLog",
         getVerifiedContract: ConfigData.SERVICE_URL_EXPLORER_API_BASE+"getVerifiedContract",
         getProjectStrcture: ConfigData.SERVICE_URL_IDE_API_BASE+"getProjectStructure",
         loadResourceContent: ConfigData.SERVICE_URL_IDE_API_BASE+"loadResourceContent",

        //auth
        login: ConfigData.SERVICE_URL_AUTH_API_BASE+"login",
        logout: ConfigData.SERVICE_URL_AUTH_API_BASE+"logout",
        signup: ConfigData.SERVICE_URL_AUTH_API_BASE+"signup",
        resetpwd: ConfigData.SERVICE_URL_AUTH_API_BASE+"resetpwd",
        updatepwd: ConfigData.SERVICE_URL_AUTH_API_BASE+"updatepwd",
        confirmPwd: ConfigData.SERVICE_URL_AUTH_API_BASE+"confirmPwd",
        getuser: ConfigData.SERVICE_URL_AUTH_API_BASE+"getuser",
        generateApiToken: ConfigData.SERVICE_URL_AUTH_API_BASE+"generateApiToken",
        getGenerateApiToken: ConfigData.SERVICE_URL_AUTH_API_BASE+"getGenerateApiToken",

        //ledgers
        getLedgers: ConfigData.SERVICE_URL_LEDGE_API_BASE+"getLedgers",
        getLedger: ConfigData.SERVICE_URL_LEDGE_API_BASE+"getLedger",
        //txs
        getTxs: ConfigData.SERVICE_URL_TX_API_BASE+"getTxs",
        getTx: ConfigData.SERVICE_URL_TX_API_BASE+"getTx",
        getLedgerTxns: ConfigData.SERVICE_URL_TX_API_BASE+"getLedgerTxns",
        getAccountTxns: ConfigData.SERVICE_URL_TX_API_BASE+"getAccountTxns",

        //Operations
        getOperations: ConfigData.SERVICE_URL_OPERATION_API_BASE+"getOperations",
        getOperation: ConfigData.SERVICE_URL_OPERATION_API_BASE+"getOperation",
        getLedgerOperations: ConfigData.SERVICE_URL_OPERATION_API_BASE+"getLedgerOperations",
        getTxnOperations: ConfigData.SERVICE_URL_OPERATION_API_BASE+"getTxnOperations",
        getAccountOperations: ConfigData.SERVICE_URL_OPERATION_API_BASE+"getAccountOperations",
        //LiquidityPools
        getLiquidityPools: ConfigData.SERVICE_URL_LP_API_BASE+"getLiquidityPools",
        getLiquidityPool: ConfigData.SERVICE_URL_LP_API_BASE+"getLiquidityPool",
        getLiquidityPoolTxns: ConfigData.SERVICE_URL_LP_API_BASE+"getLiquidityPoolTxns",
        getLiquidityPoolOperations: ConfigData.SERVICE_URL_LP_API_BASE+"getLiquidityPoolOperations",
        getAccountLiquidityPools: ConfigData.SERVICE_URL_LP_API_BASE+"getAccountLiquidityPools",

        //effectd
        getEffects: ConfigData.SERVICE_URL_EFFECT_API_BASE+"getEffects",
        getEffect: ConfigData.SERVICE_URL_EFFECT_API_BASE+"getEffect",
        getLedgerEffects: ConfigData.SERVICE_URL_EFFECT_API_BASE+"getLedgerEffects",
        getTxnEffects: ConfigData.SERVICE_URL_EFFECT_API_BASE+"getTxnEffects",
        getOperationEffects: ConfigData.SERVICE_URL_EFFECT_API_BASE+"getOperationEffects",
        getLiquidityPoolEffects: ConfigData.SERVICE_URL_EFFECT_API_BASE+"getLiquidityPoolEffects",
        getAccountEffects: ConfigData.SERVICE_URL_EFFECT_API_BASE+"getAccountEffects",

        //paymentd
        getPayments: ConfigData.SERVICE_URL_PAYMENT_API_BASE+"getPayments",
        getPayment: ConfigData.SERVICE_URL_PAYMENT_API_BASE+"getPayment",
        getLedgerPayments: ConfigData.SERVICE_URL_PAYMENT_API_BASE+"getLedgerPayments",
        getTxnPayments: ConfigData.SERVICE_URL_PAYMENT_API_BASE+"getTxnPayments",
        getAccountPayments: ConfigData.SERVICE_URL_PAYMENT_API_BASE+"getAccountPayments",

        //traded
        getTrades: ConfigData.SERVICE_URL_TRADE_API_BASE+"getTrades",
        getTrade: ConfigData.SERVICE_URL_TRADE_API_BASE+"getTrade",
        getLiquidityPoolTrades: ConfigData.SERVICE_URL_TRADE_API_BASE+"getLiquidityPoolTrades",
        getAccountTrades: ConfigData.SERVICE_URL_TRADE_API_BASE+"getAccountTrades",
        //assetd
        getAssets: ConfigData.SERVICE_URL_ASSET_API_BASE+"getAssets",
        getAsset: ConfigData.SERVICE_URL_ASSET_API_BASE+"getAsset",
        //accountd
        getAccounts: ConfigData.SERVICE_URL_ACCOUNT_API_BASE+"getAccounts",
        getAccount: ConfigData.SERVICE_URL_ACCOUNT_API_BASE+"getAccount",
        //offer
        getOffers: ConfigData.SERVICE_URL_OFFER_API_BASE+"getOffers",
        getOffer: ConfigData.SERVICE_URL_OFFER_API_BASE+"getOffer",
        getAccountOffers: ConfigData.SERVICE_URL_OFFER_API_BASE+"getAccountOffers",

        //contract
        getContract: ConfigData.SERVICE_URL_CONTRACT_API_BASE+"getContract",
        decodeAndDisassemble: ConfigData.SERVICE_URL_CONTRACT_API_BASE+"decodeAndDisassemble",

        //search
        searchBlockchain: ConfigData.SERVICE_URL_SEARCH_API_BASE+"getSearchType",

         //dashboard
         getDashboardLedgers: ConfigData.SERVICE_URL_LEDGE_API_BASE+"getDashboardLedgers",
         getDashboardTxs: ConfigData.SERVICE_URL_TX_API_BASE+"getDashboardTxs",
         getDashboardOperations: ConfigData.SERVICE_URL_OPERATION_API_BASE+"getDashboardOperations",
         getDashboardLiquidityPools: ConfigData.SERVICE_URL_LP_API_BASE+"getDashboardLiquidityPools",


     }
};
