import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IconCheck } from '@tabler/icons';
import React, { useEffect }  from 'react';
import PageContainer from 'src/components/container/PageContainer';
import CustomContent from 'src/components/shared/doc/CustomContent';
import { fetchSupporedEnv } from 'src/store/app/developer/ApiDocSlice';
import { useDispatch, useSelector } from 'src/store/Store';

const SupportedEnvironments = () => { 
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchSupporedEnv());
      }, [dispatch]);
      const result = useSelector((state) => state.apiDocReducer.result);
      
return (
  <>
 <PageContainer>
      <Grid container spacing={3} marginLeft={2}>
          <Grid item xs={12}>
          <Box>
             {/* ------------------------------------------- */}
              {/* API  Detail page */}
              {/* ------------------------------------------- */}
            <Stack p={2} gap={0} direction="column">
              <CustomContent>
                <p>  
                Every Soroban contract is meticulously compiled and built within a designated runtime environment. The following is a comprehensive list of smart contract runtime environments that we support for contract verification:
               </p>
              </CustomContent>

            </Stack>
          </Box>
          </Grid>
      </Grid>
      <Stack p={2} gap={0} direction="column" marginLeft={10} marginRight={10}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TableContainer>
                <Table sx={{  width: '100%', maxWidth: '100%' }}>
                    <TableHead>
                        <TableRow>
                        <TableCell sx={{ width: '40%' }}>Rust</TableCell>
                        <TableCell sx={{ width: '60%' }}>Soroban</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            { result.map((row: any) => {
                                return(
                                    <TableRow key={row.id}>
                                    <TableCell sx={{ width: '40%' }}>
                                        <Typography color="textSecondary" variant="subtitle2" fontWeight={600}>
                                        {row.rust} 
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: '60%' }}>
                                        <List>
                                      {
                                      row.sorobans.map((sorbanRow: any) => {
                                        return(
                                            <>                       
                                                <ListItem disableGutters key={sorbanRow.id}>
                                                    <ListItemIcon sx={{ color: 'primary.main', minWidth: '32px' }}>
                                                        <IconCheck width={18} />
                                                    </ListItemIcon>
                                                    <ListItemText>{sorbanRow.soroban}</ListItemText>
                                                </ListItem>
                                            </>
                                        )
                                        })
                                        
                                    }
                                        </List>
                                    </TableCell>           
                                  </TableRow>
                                )
                              })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </Stack>
  
  </PageContainer>
    </>
  );
};

export default SupportedEnvironments;
