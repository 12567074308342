import { Box, Grid, Link, Stack } from '@mui/material';
import React  from 'react';
import PageContainer from 'src/components/container/PageContainer';
import CustomContent from 'src/components/shared/doc/CustomContent';

const GettingStarted = () => { 

return (
  <>
 <PageContainer>
      <Grid container spacing={3} marginLeft={2}>
          <Grid item xs={12}>
          <Box>
             {/* ------------------------------------------- */}
              {/* API  Detail page */}
              {/* ------------------------------------------- */}
            <Stack p={2} gap={0} direction="column">
              <CustomContent>
                <p>  
                Soroban Explorer enhances the Stellar community's experience by providing a reliable and transparent way to validate the code underlying smart contracts on the Stellar protocol. 
                Our platform aims to improve transparency, trust, and encourage broader adoption within the Stellar network.
               </p>
              </CustomContent>
               <CustomContent>
                <p>  
                To achieve this goal, Soroban Explorer designs and implements the foundational features for compiling, building source code, and developing an intuitive interface for user interaction. 
                This smart contract explorer prioritizes user-centricity, ensuring a seamless experience for Stellar users, regardless of their technical expertise. 
                Our aim is to simplify the process of verifying smart contract code, requiring minimal technical input.
               </p>
              </CustomContent>
              <CustomContent>
                <p>  
                The core verification process involves utilizing hosted Docker container services to generate a hash of the underlying WebAssembly (wasm) bytecode. 
                This hash is then compared with the deployed contract code, validating the smart contract based on user-input parameters. 
                The entire process is presented through a user-friendly webform interface, providing in a clear "green check" for validation within the community. 
                Over time, we anticipate expanding our supported features based on the evolving demands of the community.
               </p>
              </CustomContent>
              <CustomContent>
                <p>  
                In line with our dedication to user convenience, our core product seamlessly integrates with partners, with the <Link href={'https://stellar.expert/explorer/public'} rel="noopener noreferrer" target="_blank" underline="none">StellarExpert</Link> platform being our current collaboration.
                By doing so, we contribute to the existing community standard for exploring on-chain activity across Stellar, providing enhanced value without disrupting established user habits and patter
               </p>
              </CustomContent>
              <CustomContent>
                <p>  
                We invite partners to join us in this journey by integrating our APIs into their platforms. For more details on potential partnerships, please reach out to us at 
                <Link key="Email" component="a" href="mailto:info@sorobanexp.com"> info@sorobanexp.com</Link>.
               </p>
              </CustomContent>
              <CustomContent>
                <p>  
                Your collaboration is crucial in advancing our shared goal of fostering a robust and accessible ecosystem within the Stellar community.
               </p>
              </CustomContent>
            </Stack>
          </Box>
              <div> </div>
          </Grid>
      </Grid>
  </PageContainer>
    </>
  );
};

export default GettingStarted;
