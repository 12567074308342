import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';
import { ConfigData } from 'src/utils/conf';

interface StateType {
    nextPagingToken: string;
    prePagingToken: string;
    isFirst: boolean;
    isLast: boolean;
    liquidityPools: any[];
    liquidityPool?: any;
}
const initialState = {
    liquidityPools: [],
    liquidityPool: {},
    operations: [],
    nextPagingToken: '',
    prePagingToken: '',
    isFirst: false,
    isLast: false,
  };

export const LiquidityPoolSlice = createSlice({
    name: 'LiquidityPool',
    initialState,
    reducers: {
      getLiquidityPools: (state: StateType, action) => {
        state.liquidityPools = action.payload.result.liquidityPools;
        state.nextPagingToken = action.payload.result.paging.nextPagingToken;
        state.prePagingToken = action.payload.result.paging.prevPagingToken;
        state.isFirst = action.payload.result.paging.isFirst;
        state.isLast = action.payload.result.paging.isLast;

      },
      getDashboardLiquidityPools: (state: StateType, action) => {
        state.liquidityPools = action.payload.result.liquidityPools;
      },
      getLiquidityPool: (state: StateType, action) => {
        state.liquidityPool = action.payload.result.liquidityPool;
      },
    },
});
export const fetchLiquidityPools = (pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(`${ConfigData.URL.getLiquidityPools+"?pagingToken="+ pagingToken+"&order="+ order}`);
      dispatch(getLiquidityPools(response.data));
    } catch (err) {
      throw new Error();
    }
};
export const fetchDashboardLiquidityPools = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getDashboardLiquidityPools}`);
    dispatch(getDashboardLiquidityPools(response.data));
  } catch (err) {
    throw new Error();
  }
};
export const fetchLiquidityPool = (id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getLiquidityPool+"?id="+id}`);
    dispatch(getLiquidityPool(response.data));
  } catch (err) {

    throw new Error();
  }
};
export const fetchLiquidityPoolsByAccount = (id: string, pagingToken: string, order: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${ConfigData.URL.getAccountLiquidityPools+"?id="+ id + "&pagingToken="+ pagingToken+"&order="+ order}`);
    dispatch(getLiquidityPools(response.data));
  } catch (err) {
    throw new Error();
  }
};



export const { getLiquidityPools, getDashboardLiquidityPools, getLiquidityPool} = LiquidityPoolSlice.actions;

export default LiquidityPoolSlice.reducer;