import React from 'react';
import AuthUpdatePassword from 'src/components/auth/AuthUpdatePassword';
import AuthPageContainer from 'src/components/container/AuthPageContainer';

const UpdatePassword = () => {
    return (
        <>
        <AuthPageContainer>
            <AuthUpdatePassword/>
        </AuthPageContainer>
        </>
    );
};
export default UpdatePassword;
